import {Injectable} from "@angular/core";
import {CanActivate} from "@angular/router";
import { SettingService } from './setting.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SettingsGuard implements CanActivate {

    constructor( private settingService: SettingService) {}

    canActivate() {
        return this.settingService
            .Initial(true).pipe(
                map(data => true)
            );
    }
}