import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbMenuItem
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil, filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { NbAuthService } from "@nebular/auth";
import { Router } from "@angular/router";
import { AppAuthSimpleToken } from "../../../services/models/authenticate-model";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user = {
    name: '',
    picture: 'assets/images/user.png',
  };

  currentTheme = "default";

  userMenu = [{ title: "Log out", data: "logout", tag: "header-context-menu" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private router: Router,
    private authService: NbAuthService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.authService.onTokenChange().subscribe((token: AppAuthSimpleToken) => {
      if (token.isValid()) {
        const { userName } = token.getPayload();
        this.user.name = userName;
      }
    });

    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "header-context-menu"))
      .subscribe(menu => {
        if (menu.item.data === "logout") {
          this.authService.logout("email").subscribe(() => {
            this.router.navigate(["auth/login"]);
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
