import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as ApiServiceProxies from './service-proxies';
import { environment } from '../../../environments/environment';
import { AppAuthSimpleInterceptor } from '../auth/simple-interceptor';
import { FileServicesCustomServiceProxy } from './file-service-custom';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.RewardDetailServicesServiceProxy,
        ApiServiceProxies.RewardServicesServiceProxy,
        ApiServiceProxies.CategoryServicesServiceProxy,
        ApiServiceProxies.CountryServicesServiceProxy,
        ApiServiceProxies.FileServicesServiceProxy,
        ApiServiceProxies.OrderServicesServiceProxy,
        ApiServiceProxies.ReportServicesServiceProxy,
        ApiServiceProxies.MailServicesServiceProxy,
        FileServicesCustomServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AppAuthSimpleInterceptor, multi: true },
        {
            provide: ApiServiceProxies.API_BASE_URL,
            useValue: environment.apiRoot
        }
    ]
})
export class ServiceProxyModule { 
    
}
