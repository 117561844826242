import { Injectable } from "@angular/core";
import {
  IApplicationSettings,
  createApplicationSettings,
  createSelectItem
} from "../models/app-models";
import {
  CountryServicesServiceProxy,
  CategoryServicesServiceProxy
} from "../service-proxies/service-proxies";
import { combineLatest, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AppRewardType } from "../models/rewards-model";
import { AppOrderType } from "../models/orders-models";

@Injectable({
  providedIn: "root"
})
export class SettingService {
  private appSettings: IApplicationSettings;
  private isInitial = false;
  constructor(
    private countryServicesServiceProxy: CountryServicesServiceProxy,
    private categoryServicesServiceProxy: CategoryServicesServiceProxy
  ) {}
  Initial(restart): Observable<any> {
    if (this.isInitial && restart == false) {
      return of(this.appSettings);
    }

    this.isInitial = true;
    return combineLatest(
      this.countryServicesServiceProxy.getAll(undefined),
      this.categoryServicesServiceProxy.getAll(undefined)
    ).pipe(
      map(([countries, categories]) => {
        const itemTypes = [
          createSelectItem(
            AppRewardType.EVoucher.toString(),
            AppRewardType.EVoucher
          ),
          createSelectItem(
            AppRewardType.Physical.toString(),
            AppRewardType.Physical
          )
        ];
        const orderStatus = [
          createSelectItem(AppOrderType.New.toString(), AppOrderType.New),
          createSelectItem(AppOrderType.Processing.toString(), AppOrderType.Processing),
          createSelectItem(AppOrderType.Delivered.toString(), AppOrderType.Delivered),
          createSelectItem(AppOrderType.Done.toString(), AppOrderType.Done),
          createSelectItem(AppOrderType.Cancelled.toString(), AppOrderType.Cancelled),
        ];
        this.appSettings = createApplicationSettings(
          countries.items.map(c => createSelectItem(c.id, c.name)),
          categories.items.map(c => createSelectItem(c.id, c.name)),
          itemTypes,
          orderStatus
        );
      })
    );
  }
  geApplicationSettings(): IApplicationSettings {
    return this.appSettings;
  }
}
