import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Power by <a href="https://srcvn.com/">SRC</a></span>
  `,
})
export class FooterComponent {
}
