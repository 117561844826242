import { Component, OnInit } from '@angular/core';
import { SettingService } from './services/app/setting.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor() {
  }
}
