import { Inject, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  NB_AUTH_INTERCEPTOR_HEADER,
  NbAuthJWTToken,
  NbAuthService,
} from "@nebular/auth";
import { AppAuthSimpleToken } from "../models/authenticate-model";

export interface IValidationErrorInfo {
  message: string;
  members: string[];
}

export interface IErrorInfo {
  code: number;
  message: string;
  details: string;
  validationErrors: IValidationErrorInfo[];
}

export interface IAjaxResponse {
  success: boolean;

  result?: any;

  targetUrl?: string;

  error?: IErrorInfo;

  unAuthorizedRequest: boolean;

  __abp: boolean;
}

@Injectable()
export class AppAuthSimpleInterceptor implements HttpInterceptor {
  defaultError = <IErrorInfo>{
    message: "An error has occurred!",
    details: "Error details were not sent by server.",
  };

  defaultError401 = <IErrorInfo>{
    message: "You are not authenticated!",
    details:
      "You should be authenticated (sign in) in order to perform this operation.",
  };

  defaultError403 = <IErrorInfo>{
    message: "You are not authorized!",
    details: "You are not allowed to perform this operation.",
  };

  defaultError404 = <IErrorInfo>{
    message: "Resource not found!",
    details: "The resource requested could not be found on the server.",
  };
  constructor(
    private injector: Injector,
    private router: Router,

    @Inject(NB_AUTH_INTERCEPTOR_HEADER)
    protected headerName: string = "Authorization"
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const self = this;
    return this.authService.getToken().pipe(
      switchMap((token: AppAuthSimpleToken) => {
        if (token && token.getValue()) {
          req = req.clone({
            setHeaders: {
              [self.headerName]: "Bearer " + token.getValue(),
            },
          });
        }
        return next.handle(req).pipe(
          // catchError(error => {
          //   // if (error instanceof HttpErrorResponse && error.status === 401) {
          //   //   return this.(request, next, error);
          //   // }
          //   return self.handleErrorResponse(error);
          // }),
          switchMap((event) => {
            return self.handleSuccessResponse(event);
          }),
          catchError((error) => {
            return self.handleErrorResponse(event);
          })
        );
      })
    );
  }

  protected handleErrorResponse(error: any): Observable<never> {
    if (!(error.currentTarget.response instanceof Blob)) {
      return throwError(error);
    }

    this.handleNonAbpErrorResponse(error.currentTarget);

    return this.blobToText(error.currentTarget.response).pipe(
      switchMap((json) => {
        const errorBody = json == "" || json == "null" ? {} : JSON.parse(json);
        const errorResponse = new HttpResponse({
          headers: error.headers,
          status: error.status,
          body: errorBody,
        });

        var ajaxResponse = this.getAbpAjaxResponseOrNull(errorResponse);

        if (ajaxResponse != null) {
          this.handleAbpResponse(errorResponse, ajaxResponse);
        } else {
          this.handleNonAbpErrorResponse(errorResponse);
        }

        return throwError(error);
      })
    );
  }
  handleNonAbpErrorResponse(response: HttpResponse<any>) {
    const self = this;

    switch (response.status) {
      case 401:
        this.router.navigate(["auth/login"]);
        break;
      case 403:
        console.error(self.defaultError403);
        break;
      case 404:
        console.error(self.defaultError404);
        break;
      default:
        console.error(self.defaultError);
        break;
    }
  }
  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }
  blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next("");
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = function () {
          observer.next(this.result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }
  handleAbpResponse(
    response: HttpResponse<any>,
    ajaxResponse: IAjaxResponse
  ): HttpResponse<any> {
    var newResponse: HttpResponse<any>;

    if (ajaxResponse.success) {
      newResponse = response.clone({
        body: ajaxResponse.result,
      });

      if (ajaxResponse.targetUrl) {
        this.handleTargetUrl(ajaxResponse.targetUrl);
      }
    } else {
      newResponse = response.clone({
        body: ajaxResponse.result,
      });

      if (!ajaxResponse.error) {
        ajaxResponse.error = this.defaultError;
      }

      console.error(ajaxResponse.error);

      if (response.status === 401) {
        this.handleUnAuthorizedRequest(null, ajaxResponse.targetUrl);
      }
    }

    return newResponse;
  }

  getAbpAjaxResponseOrNull(response: HttpResponse<any>): IAjaxResponse | null {
    if (!response || !response.headers) {
      return null;
    }
    var contentType = response.headers.get("Content-Type");
    if (!contentType) {
      console.warn("Content-Type is not sent!");
      return null;
    }
    if (contentType.indexOf("application/json") < 0) {
      console.warn("Content-Type is not application/json: " + contentType);
      return null;
    }

    var responseObj = JSON.parse(JSON.stringify(response.body));
    if (!responseObj.__abp) {
      return null;
    }

    return responseObj as IAjaxResponse;
  }

  handleTargetUrl(targetUrl: string): void {
    if (!targetUrl) {
      location.href = "/";
    } else {
      location.href = targetUrl;
    }
  }

  handleResponse(response: HttpResponse<any>): HttpResponse<any> {
    var ajaxResponse = this.getAbpAjaxResponseOrNull(response);
    if (ajaxResponse == null) {
      return response;
    }

    return this.handleAbpResponse(response, ajaxResponse);
  }
  handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
    const self = this;

    if (messagePromise) {
      messagePromise.done(() => {
        self.handleTargetUrl(targetUrl || "/");
      });
    } else {
      self.handleTargetUrl(targetUrl || "/");
    }
  }
  protected handleSuccessResponse(
    event: HttpEvent<any>
  ): Observable<HttpEvent<any>> {
    var self = this;

    if (event instanceof HttpResponse) {
      if (event.body instanceof Blob && event.body.type) {
        const isJson = event.body.type.indexOf("application/json") >= 0;
        if (isJson) {
          return self.blobToText(event.body).pipe(
            map((json) => {
              const responseBody = json == "null" ? {} : JSON.parse(json);

              var modifiedResponse = self.handleResponse(
                event.clone({
                  body: responseBody,
                })
              );

              return modifiedResponse.clone({
                body: new Blob([JSON.stringify(modifiedResponse.body)], {
                  type: "application/json",
                }),
              });
            })
          );
        } else {
          var cd = event.headers.get("Content-Disposition");
          var regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var match = regex.exec(cd);
          var fileName = match[1] || "report.dat";
          fileName = fileName.replace(/\"/g, "");
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(event.body, fileName);
          } else {
            var a = document.createElement("a"),
              url = URL.createObjectURL(event.body);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              a.remove();
            }, 0);
          }
          return of(event);
        }
      }
    }

    return of(event);
  }
}
