import {
  AppTypeRewardDetailStatus,
  IAppEditReward,
  IAppRewardDetail
} from "./rewards-model";

export enum AppOrderType {
  New = "New",
  Processing = "Processing",
  Delivered = "Delivered",
  Done = "Done",
  Cancelled = "Cancelled"
}

export interface IAppOrderRewardDetail {
  rewardId: number;
  code: string;
  startDate: Date;
  expiredDate: Date;
  status: AppTypeRewardDetailStatus;
  createdDate: Date;
  updatedDate: Date;
  id: number;
}
export interface IOrderDetailResponses {
  reward: IAppEditReward;
  orderDetailId: number;
  quantity: number;
  instock: number,
  rewardDetails: IAppRewardDetail[];
}
export interface IAppOrder {
  status: AppOrderType;
  recipientName: string;
  companyName: string;
  workshopAddress: string;
  mobileNumber: string;
  shareRedemptionReferenceId: string;
  note: string;

  account: string;
  city : string;
  deliveredAddress: string;
  deliveryDate: Date;
  receivingDate: Date;
  distributor: string;
  firstName: string;
  lastName: string;
  receivingPerson: string;
  redemptionDate: Date;
  redemptionItem: string;
  redemptionPoints: number;
  trackingCode: string;
  workshopCode: string;

  createdDate: Date;
  updatedDate: Date;
  rewards: IOrderDetailResponses[];
  id: number;
  canCancel: boolean;
}

export function createAppOrderRewardDetai(
  rewardId: number,
  code: string,
  startDate: Date,
  expiredDate: Date,
  status: AppTypeRewardDetailStatus,
  createdDate: Date,
  updatedDate: Date,
  id: number
): IAppOrderRewardDetail {
  return {
    rewardId,
    code,
    startDate,
    expiredDate,
    status,
    createdDate,
    updatedDate,
    id
  };
}
export function createOrderDetailResponses(
  reward: IAppEditReward,
  orderDetailId: number,
  quantity: number,
  instock: number,
  rewardDetails: IAppRewardDetail[]
): IOrderDetailResponses {
  return {
    reward,
    orderDetailId,
    quantity,
    instock,
    rewardDetails
  };
}
export function createAppOrder(
  status: AppOrderType,
  recipientName: string,
  companyName: string,
  workshopAddress: string,
  mobileNumber: string,
  shareRedemptionReferenceId: string,
  note: string,
  createdDate: Date,
  updatedDate: Date,
  rewards: IOrderDetailResponses[],
  id: number,
  account: string,
  city : string,
  deliveredAddress: string,
  deliveryDate: Date,
  receivingDate: Date,
  distributor: string,
  firstName: string,
  lastName: string,
  receivingPerson: string,
  redemptionDate: Date,
  redemptionItem: string,
  redemptionPoints: number ,
  trackingCode: string,
  workshopCode: string,
  canCancel : boolean
): IAppOrder {
  return {
    status,
    recipientName,
    companyName,
    workshopAddress,
    mobileNumber,
    shareRedemptionReferenceId,
    note,
    createdDate,
    updatedDate,
    rewards,
    id,
    account,
    city,
    deliveredAddress,
    deliveryDate,
    receivingDate,
    distributor,
    firstName,
    lastName,
    receivingPerson,
    redemptionDate,
    redemptionItem,
    redemptionPoints,
    trackingCode,
    workshopCode,
    canCancel
  };
}
