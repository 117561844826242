export interface ISelectItem {
  key: string | number;
  value: string;
}

export interface IApplicationSettings {
  countries: ISelectItem[];
  catalogs: ISelectItem[];
  itemTypes: ISelectItem[];
  orderStatus: ISelectItem[]
}
export function createSelectItem(
  key: string | number,
  value: string
): ISelectItem {
  return {
    key,
    value
  };
}
export function createApplicationSettings(
  countries: ISelectItem[],
  catalogs: ISelectItem[],
  itemTypes: ISelectItem[],
  orderStatus: ISelectItem[]
): IApplicationSettings {
  return {
    countries,
    catalogs,
    itemTypes,
    orderStatus
  };
}
