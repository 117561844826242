import {
  AuthenticateModel
} from "../service-proxies/service-proxies";
import { NbAuthSimpleToken } from "@nebular/auth";
export interface ITokenData {
  accessToken: string;
  encryptedAccessToken: string;
  expireInSeconds: number;
  userName: string;
  userId: number;
}
export function createTokenData(
  accessToken: string,
  encryptedAccessToken: string,
  expireInSeconds: number,
  userName: string,
  userId: number
): ITokenData {
  return {
    accessToken,
    encryptedAccessToken,
    expireInSeconds,
    userName,
    userId
  };
}
export class AppAuthSimpleToken extends NbAuthSimpleToken {
  static NAME = "app:auth:oauth:token";

  constructor(
    protected data: ITokenData,
    ownerStrategyName: string,
    createdAt?: Date
  ) {
    super(data, ownerStrategyName, createdAt);

    this.parsePayload();
    this.createdAt = this.prepareCreatedAt(createdAt);
  }

  protected parsePayload(): any {
    this.payload = this.data;
  }

  protected prepareCreatedAt(date: Date) {
    return date ? date : new Date();
  }
  getCreatedAt(): Date {
    return this.createdAt;
  }
  getValue(): string {
    return this.data.accessToken;
  }

  getOwnerStrategyName(): string {
    return this.ownerStrategyName;
  }
  isValid(): boolean {
    return !!this.getValue();
  }
  toString(): string {
    return !!this.token ? this.token : "";
  }
}

export function createAuthenticateModel(
  userNameOrEmailAddress: string,
  password: string
): AuthenticateModel {
  return new AuthenticateModel({
    userNameOrEmailAddress,
    password,
    rememberClient: true
  });
}
