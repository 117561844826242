import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NbAuthModule } from "@nebular/auth";
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  NbCardModule,
  NbLayoutModule,
  NbIconModule
} from "@nebular/theme";
import { AppAuthComponent } from "./auth.component";
import { ThemeModule } from "../../@theme/theme.module";
import { AppLoginComponent } from './login/login.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    ThemeModule,
    NbCardModule,
    NbLayoutModule,
    NbIconModule,
    //NgxAuthRoutingModule,

    NbAuthModule
  ],
  declarations: [AppAuthComponent,AppLoginComponent],
  exports:[AppLoginComponent,AppAuthComponent],
  providers: []
})
export class NgxAuthModule {
  
}
