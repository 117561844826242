export enum AppRewardType {
  EVoucher = "EVoucher",
  Physical = "Physical"
}
export enum AppTypeUpload {
  Image = "Image",
  Document = "Document"
}
export enum Country {
  VietNam = 1
}

export interface IAppFileAttachment {
  id: number;
  type: AppTypeUpload;
}

export enum AppTypeRewardDetailStatus {
  Available = 'Available',
  Used = 'Used',
}

export enum AppTypeRewardDetailUsedStatus {
  Unused = 'Unused',
  Used = 'Used',
  Expired = 'Expired'
}

export interface IAppRewardDetail {
  id: number;
  code: string;
  rewardId: number;
  startDate: Date;
  expiredDate: Date;
  createDate: Date;
  updateDate: Date;
  status: AppTypeRewardDetailStatus,
  usedStatus: AppTypeRewardDetailUsedStatus
}

export interface IAppEditReward {
  merchantName: string;
  brandName: string;
  itemNameEnglish: string;
  itemNameLocal: string;
  shortDescription: string;
  shortDescriptionLocal: string;
  itemExtraDescription: string;
  itemExtraDescriptionLocal: string;
  itemSKU: string;
  itemType: AppRewardType;
  originalPoints: number;
  purchasedValue: number;
  marketValue: number;
  countryId: number;
  categoryId: number;
  createDate: Date;
  updateDate: Date;
  fileAttachments: IAppFileAttachment[];
  id: number;
  canDelete: boolean;
  evoucherUrl: string;
  egiftItemId: number;
}
export function createFileAttachment(
  id: number,
  type: AppTypeUpload
): IAppFileAttachment {
  return {
    id,
    type
  };
}
export function createRewardDetail(
  id: number,
  code: string,
  rewardId: number,
  startDate: Date,
  expiredDate: Date,
  createDate: Date,
  updateDate: Date,
  status: AppTypeRewardDetailStatus,
  usedStatus: AppTypeRewardDetailUsedStatus
): IAppRewardDetail {
  return {
    id,
    code,
    rewardId,
    startDate,
    expiredDate,
    createDate,
    updateDate,
    status,
    usedStatus
  };
}

export function updateRewardDetail(
  id: number,
  code: string,
  rewardId: number,
  startDate: Date,
  expiredDate: Date,
  createDate: Date,
  updateDate: Date,
  status: AppTypeRewardDetailStatus,
  usedStatus: AppTypeRewardDetailUsedStatus
): IAppRewardDetail {
  return {
    id,
    code,
    rewardId,
    startDate,
    expiredDate,
    createDate,
    updateDate,
    status,
    usedStatus
  };
}

export function createAppEditReward(
  merchantName: string,
  brandName: string,
  itemNameEnglish: string,
  itemNameLocal: string,
  shortDescription: string,
  shortDescriptionLocal: string,
  itemExtraDescription: string,
  itemExtraDescriptionLocal: string,
  itemSKU: string,
  itemType: AppRewardType,
  originalPoints: number,
  purchasedValue: number,
  marketValue: number,
  countryId: number,
  categoryId: number,
  createDate: Date,
  updateDate: Date,
  fileAttachments: IAppFileAttachment[],
  id: number,
  canDelete: boolean,
  evoucherUrl: string,
  egiftItemId: number
): IAppEditReward {
  return {
    merchantName,
    brandName,
    itemNameEnglish,
    itemNameLocal,
    shortDescription,
    shortDescriptionLocal,
    itemExtraDescription,
    itemExtraDescriptionLocal,
    itemSKU,
    itemType,
    originalPoints,
    purchasedValue,
    marketValue,
    countryId,
    categoryId,
    createDate,
    updateDate,
    fileAttachments,
    id,
    canDelete,
    evoucherUrl,
    egiftItemId
  };
}
export interface IAppRewardStatus {
  available: number;
  used: number;
}
export function createAppRewardStatus(
  available: number,
  used: number
): IAppRewardStatus {
  return {
    available,
    used
  };
}
export interface IApppCreateListRewardDetail {
  code: string;
  startDate: Date | undefined;
  expiredDate: Date | undefined;
}
export interface IAppCreateListRewardDetailRequest {
  rewardId: number;
  rewardDetails: IApppCreateListRewardDetail[];
}
export function createApppCreateListRewardDetail(
  code: string,
  startDate: Date | undefined,
  expiredDate: Date | undefined
): IApppCreateListRewardDetail {
  return {
    code,
    startDate,
    expiredDate
  };
}
export function createAppCreateListRewardDetailRequest(
  rewardId: number,
  rewardDetails: IApppCreateListRewardDetail[]
): IAppCreateListRewardDetailRequest {
  return {
    rewardId,
    rewardDetails
  };
}

