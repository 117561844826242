import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { Observable, of as observableOf, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import {
  NbPasswordAuthStrategyOptions,
  NbAuthStrategy,
  NbAuthStrategyClass,
  passwordStrategyOptions,
  NbAuthResult,
  NbAuthIllegalTokenError,
  NbAuthToken
} from "@nebular/auth";

import { TokenAuthServiceProxy } from "../service-proxies/service-proxies";
import {
  createAuthenticateModel,
  AppAuthSimpleToken,
  createTokenData
} from "../models/authenticate-model";

@Injectable()
export class AppPasswordAuthStrategy extends NbAuthStrategy {
  protected defaultOptions: NbPasswordAuthStrategyOptions = passwordStrategyOptions;

  static setup(
    options: NbPasswordAuthStrategyOptions
  ): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [AppPasswordAuthStrategy, options];
  }

  constructor(
    protected http: HttpClient,
    private route: ActivatedRoute,
    protected apiLoginService: TokenAuthServiceProxy
  ) {
    super();
  }

  authenticate(data?: any): Observable<NbAuthResult> {
    const module = "login";
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);
    return this.apiLoginService
      .authenticate(createAuthenticateModel(data.email, data.password))
      .pipe(
        map(res => {
          if (this.getOption(`${module}.alwaysFail`)) {
            throw this.createFailResponse(data);
          }
          return res;
        }),
        map(res => {
          return new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption("messages.getter")(module, res, this.options),
            this.createSimpleToken(data.email, res)
          );
        }),
        catchError(res => {
          return this.handleResponseError(res, module);
        })
      );
  }

  createSimpleToken(userName: string, data: any): NbAuthToken {
    const {
      accessToken,
      encryptedAccessToken,
      expireInSeconds,
      userId
    } = data;
    return new AppAuthSimpleToken(
      createTokenData(
        accessToken,
        encryptedAccessToken,
        expireInSeconds,
        userName,
        userId
      ),
      "email"
    );
  }

  register(data?: any): Observable<NbAuthResult> {
   return null;
  }

  requestPassword(data?: any): Observable<NbAuthResult> {
    return null;
  }

  resetPassword(data: any = {}): Observable<NbAuthResult> {
    return null;
  }

  logout(): Observable<NbAuthResult> {
    const module = "logout";
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    return of(new NbAuthResult(true, null, "/", [], ""));
  }

  refreshToken(data?: any): Observable<NbAuthResult> {
   return null;
  }

  protected handleResponseError(
    res: any,
    module: string
  ): Observable<NbAuthResult> {
    let errors = [];
    if (res instanceof HttpErrorResponse) {
      errors = this.getOption("errors.getter")(module, res, this.options);
    } else if (res instanceof NbAuthIllegalTokenError) {
      errors.push(res.message);
    } else {
      errors.push("Something went wrong.");
    }
    return observableOf(
      new NbAuthResult(
        false,
        res,
        this.getOption(`${module}.redirect.failure`),
        errors
      )
    );
  }
}
