import { Observable } from "rxjs";
import { Injectable, Optional, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_BASE_URL, TypeUpload, FileDto } from "./service-proxies";
import { map } from "rxjs/operators";
@Injectable()
export class FileServicesCustomServiceProxy {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "";
  }
  createFile(
    type: TypeUpload,
    rewardId: number,
    body: Blob
  ): Observable<FileDto> {
    let url_ = this.baseUrl + "/api/services/app/FileServices/CreateFile?";
    if (type === null) throw new Error("The parameter 'type' cannot be null.");
    else if (type !== undefined)
      url_ += "Type=" + encodeURIComponent("" + type) + "&";
    if (rewardId === null)
      throw new Error("The parameter 'rewardId' cannot be null.");
    else if (rewardId !== undefined)
      url_ += "RewardId=" + encodeURIComponent("" + rewardId) + "&";
    url_ = url_.replace(/[?&]$/, "");

    const formData: FormData = new FormData();
    formData.append("Data", body, "image.png");

    return this.http.post(url_, formData).pipe(
      map((data: any) => {
        return new FileDto({
          fileName: data.result.fileName,
          data: data.result.data,
          id: data.result.id,
          rewardId: data.result.rewardId,
          type: data.result.type
        });
      })
    );
  }
  updateFile(
    type: TypeUpload,
    id: number,
    rewardId: number,
    body: Blob
  ): Observable<FileDto> {
    let url_ = this.baseUrl + "/api/services/app/FileServices/UpdateFile?";
    url_ += "Type=" + encodeURIComponent("" + type) + "&";
    url_ += "RewardId=" + encodeURIComponent("" + rewardId) + "&";
    url_ += "Id=" + encodeURIComponent("" + id) + "&";
    url_ = url_.replace(/[?&]$/, "");

    const formData: FormData = new FormData();
    formData.append("Data", body, "image.png");

    return this.http.put(url_, formData).pipe(
      map((data: any) => {
        return new FileDto({
          fileName: data.result.fileName,
          data: data.result.data,
          id: data.result.id,
          rewardId: data.result.rewardId,
          type: data.result.type
        });
      })
    );
  }

  postFile(fileToUpload: File, id: number): Observable<boolean> {
    console.log(fileToUpload);
    const url = this.baseUrl + "/api/services/app/RewardDetailServices/Upload?rewardId=" + id;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http
      .post(url, formData)
      .pipe(
        map(() => { return true; })
      );
}
}